import React from 'react';
import user from './assets/images/user-friend.png';
import completed from './assets/images/completed.png';
import mitushi from './assets/images/mitushi.png';
import bgLogo from './assets/images/bg-logo-opcity.png';
import socialLinks from './socialLinks.json';

const whatsapp = '9727848918'

// Function to create the WhatsApp URL with a dynamic message
const createWhatsAppLink = () => {
    const message = `Hello, I am interested in booking a free session. Please provide me with more details.`;
    return `https://wa.me/${whatsapp}?text=${encodeURIComponent(message)}`;
};

export default function RecentClasses() {
    return (
        <>
            <section className='bg-floral py-20 relative'>
                <img src={bgLogo} alt="Bg Logo" className='absolute -bottom-[32px] right-0' />
                <div className='container'>
                    <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-10'>
                        <div>
                            <img src={mitushi} alt="Mitushi" className='w-full rounded-xl' />
                        </div>
                        <div>
                            <h3 className="text-2xl lg:text-4xl font-heading font-regular text-[#22221F]">Hey! I’m Mitushi Makwana and I’m a certified yoga trainer from Arogyam Institute.</h3>
                            <p className="text-base font-regular text-[#22221F] mt-7">A qualified & experienced Child Birth Educator, Mitushi has received
                                training from Rita’s Pregnancy 101, Ahmedabad in 2017.Mitushi has
                                created the concept of comprehensive Pre Pregnancy, and delivered
                                Pregnancy and Postnatal classes: Pregnancy Serenity, which covers not just
                                the physical fitness aspect of pregnancy, but also addresses psychological
                                and spiritual needs that pregnancy can bring about.</p>
                            <p>She is also a Certified Yoga Trainer from Arogyam Institute.</p>
                            <p>She has also completed Diet and Nutrition course for Mothers and child.</p>
                            <ul className="space-y-3 mt-7">
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5 flex-none">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium flex-1">Created flagship antenatal & postnatal program for the growing Indian 
                                    families in Vadodara.</span>
                                </li>
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5 flex-none">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium flex-1">More than 400 pregnant women & their partners have taken advantage of 
                                    the comprehensive antenatal & postnatal program.</span>
                                </li>
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium">Tie up with 3 large hospital in Vadodara.</span>
                                </li>
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium">Provides Online Prenatal Classes over the world</span>
                                </li>
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium">Runs Online Postnatal Classes for new mothers </span>
                                </li>
                                <li className='flex'>
                                    <span class="mr-2.5 mt-0.5 flex-none">
                                        <img src={completed} alt="User" className="inline-block" />
                                    </span>
                                    <span className="text-[#22221F] text-base font-medium flex-1">Attended online workshop to manage PCOD & Hormonal Imbalance to
                                    support to conceive. </span>
                                </li>
                            </ul>
                            <a
                                href={createWhatsAppLink()}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-[#C04E89] border border-[#C04E89] rounded-2xl inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white mt-7"
                            >
                                Book Free Session
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
