import React from 'react';
import { Link } from 'react-router-dom';
import contactDetails from './contactDetails.json'; // Import the JSON file
import mail from './assets/images/mail.png';
import phoneIcon from './assets/images/phone.png';
import location from './assets/images/location.png';
import instagram from './assets/images/instagram.svg';
import snapchat from './assets/images/snapchat.svg';
import youtube from './assets/images/youtube.svg';
import bbf from './assets/images/bbf-medium.svg';

export default function Footer() {
    const { email, phone,location: address } = contactDetails;

    return (
        <>
            <section className='bg-[#22221F] py-20'>
                <div className='container'>
                    {/* <nav>
                        <ul className="block md:flex items-center justify-center">
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] lg:inline-flex">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] md:ml-8 lg:inline-flex">
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] md:ml-8 lg:inline-flex">
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] md:ml-8 lg:inline-flex">
                                    Classes
                                </Link>
                            </li>
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] md:ml-8 lg:inline-flex">
                                    Expertises
                                </Link>
                            </li>
                            <li>
                                <Link to=""
                                    className="flex py-2 text-base font-regular text-[#F9F5E8] md:ml-8 lg:inline-flex">
                                    Gallery
                                </Link>
                            </li>
                        </ul>
                    </nav> */}
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-5 mt-7'>
                        <div className='bg-[#262623] rounded-2xl p-6'>
                            <div className='flex items-center justify-center'>
                                <img src={mail} alt="email" className='mr-3' />
                                <Link to={`mailto:${email}`} className="text-base xl:text-xl text-[#F9F5E8] font-regular" target='_blank'>
                                    {email}
                                </Link>
                            </div>
                        </div>
                        <div className='bg-[#262623] rounded-2xl p-6'>
                            <div className='flex items-center justify-center'>
                                <img src={phoneIcon} alt="phone" className='mr-3' />
                                <Link to={`tel:${phone}`} className="text-base xl:text-xl text-[#F9F5E8] font-regular">
                                    {phone}
                                </Link>
                            </div>
                        </div>
                        <div className='bg-[#262623] rounded-2xl p-6'>
                            <div className='flex items-center justify-center'>
                                <img src={location} alt="location" className='mr-3' />
                                <p className="text-base xl:text-xl text-[#F9F5E8] font-regular">
                                    {address.address}, {address.city}, {address.state} {address.zip}, {address.country}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center mt-7'>
                        <Link to="https://www.instagram.com/thepregnancyserenity?igsh=MTIzM2xmdDd5a3I3dw==" className="text-xl text-[#F9F5E8]" target='_blank'>
                            <img src={instagram} alt="instagram" className='mr-5'/>
                        </Link>
                        {/* <Link to="" className="text-xl text-[#F9F5E8]">
                            <img src={bbf} alt="bbf" className='mr-5' />
                        </Link>
                        <Link to="" className="text-xl text-[#F9F5E8]">
                            <img src={snapchat} alt="snapchat" className='mr-5' />
                        </Link>
                        <Link to="" className="text-xl text-[#F9F5E8]">
                            <img src={youtube} alt="youtube" className='mr-5' />
                        </Link> */}
                    </div>
                    <div className='border-b border-[#ffffff30] my-10'></div>
                    <div className='block md:flex items-center justify-between text-center md:text-start'>
                        <p className='text-base text-white font-regular'>Copyright © 2024 <Link href='#' className='text-[#C04E89]'>Pregnancy Serenity</Link></p>
                        <p className='text-base text-white font-regular mt-4 md:mt-0'>Powered by: <Link href='#' className='text-[#C04E89]'>BSP Technologies</Link></p>
                    </div>
                </div>
            </section>
        </>
    );
}
