import React from 'react';
import wordwide from './assets/images/world-wide.png';
import flag from './assets/images/canada.png';
import flag2 from './assets/images/united-kingdom.png';
import flag3 from './assets/images/england.png';
import flag4 from './assets/images/france.png';
import flag5 from './assets/images/india.png';
import flag6 from './assets/images/switzerland.png';
import flag7 from './assets/images/germany.png';
import socialLinks from './socialLinks.json';

const whatsapp='9727848918'

// Function to create the WhatsApp URL with a dynamic message
const createWhatsAppLink = () => {
    const message = `Hello, I am interested in booking a session. Please provide me with more details.`;
    return `https://wa.me/${whatsapp}?text=${encodeURIComponent(message)}`;
};

export default function WorldWide() {
    return (
        <>
            <section className='py-20 h-[750px] relative flex items-center bg-no-repeat bg-cover bg-right after:absolute after:bg-gradient-to-r  from-[#22221F] to-[#22221f00] after:top-0 after:bottom-0 after:h-full after:w-full' style={{ backgroundImage: `url(${wordwide})` }}>
                <div className='container'>
                    <div className="gri grid-cols-2 relative z-10">
                        <h2 className='text-5xl font-heading font-regular text-white max-w-[525px]'>Our online services provided worldwide</h2>
                        <div className='block md:flex md:gap-12 mt-7'>
                            <div>
                                <p className="text-white font-semibold text-xl mb-4">Pre conception</p>
                                <p className="text-white font-semibold text-xl mb-4">Meditation batch</p>
                                <p className="text-white font-semibold text-xl mb-4">Garbh sanskar</p>
                                <p className="text-white font-semibold text-xl">Postnatal Classes</p>
                            </div>
                            <div className='mt-4 md:mt-0'>
                                <p className="text-white font-semibold text-xl mb-4">IVF special batch</p>
                                <p className="text-white font-semibold text-xl mb-4">Prenatal classes</p>
                                <p className="text-white font-semibold text-xl">Information/Lamaze classes</p>
                            </div>
                        </div>
                        <div className='flex flex-wrap items-center gap-4 md:gap-0 mt-7'>
                            <img src={flag} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag2} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag3} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag4} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag5} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag6} alt="Flag" className='mr-4 inline-block' />
                            <img src={flag7} alt="Flag" className='mr-4 inline-block' />
                        </div>
                        <a 
                            href={createWhatsAppLink()} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="bg-[#C04E89] border border-[#C04E89] rounded-2xl inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white mt-8"
                        >
                            Book Session
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}
