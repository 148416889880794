import React, { useState } from 'react';
import socialLinks from './socialLinks.json'; // Import the JSON file
import logo from './assets/images/logo.png';
import whatsapp from './assets/images/whatsapp.png';
import instagram from './assets/images/instagram.png';
import facebook from './assets/images/facebook.png';

const Sidebar = ({ sidebarArray, sidebarToggle, handleNavLinkClick }) => {
    return (
        <aside
            className={`fixed left-0 top-0 z-30 overflow-y-auto min-h-full max-h-full xl:hidden bg-floral shadow-[0_0_106px_-15px_rgba(0,0,0,0.3)] block flex-col  
        ${sidebarToggle ? "translate-x-0 w-[252px]" : "translate-x-[-300px] w-[252px]"}`}
        >
            <div className="overflow-y-auto duration-300 ease-linear">
                <div className='px-[20px] mt-4'>
                    <img src={logo} alt="Logo" className="inline-block" />
                </div>
                <div className='border-b border-t-0 border-Amour mt-6'></div>
                <nav className="py-[10px] px-[20px] mt-4">
                    <div>
                        <ul className="flex flex-col gap-1.5">
                            {sidebarArray.map((item, index) => (
                                <li className="mb-[10px]" key={index}>
                                    <a href={`#${item.link}`} onClick={handleNavLinkClick}>
                                        <div className="text-[#22221F] text-[16px] font-normal font-body ml-2">
                                            {item.name}
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
                <div className='px-[20px]'>
                    <a href={socialLinks.whatsapp.url} target="_blank" rel="noopener noreferrer" className="bg-green border border-green rounded-full inline-flex items-center justify-center py-1.5 px-3 text-center text-base font-semibold text-white mt-4">
                        Call on whatsapp
                        <img src={whatsapp} alt="Whatsapp" className="ml-3 inline-block" />
                    </a>
                    <a href={socialLinks.facebook.url} target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="Facebook" className="inline-block mr-4 mt-4" />
                    </a>
                    <a href={socialLinks.instagram.url} target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" className="inline-block mt-4" />
                    </a>
                </div>
            </div>
        </aside>
    );
};

export default function Header() {
    const [sidebarToggle, setSidebarToggle] = useState(false);

    const handleNavLinkClick = () => {
        setSidebarToggle(false);
    };

    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };

    const sidebarArray = [
        { name: "Home", link: "home" },
        { name: "About", link: "about" },
        { name: "Classes", link: "class" },
        { name: "Testimonials", link: "testimonial" },
        // Add more sections as needed
    ];

    return (
        <>
            <header className="w-full bg-floral border-t-0 border-b border-Amour">
                <div className="container mx-auto">
                    <div className="relative flex items-center justify-between py-5">
                        <div className="max-w-full px-4 w-60">
                            <a href="#home" className="block w-full">
                                <img src={logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="hidden xl:block">
                            <nav>
                                <ul className="flex items-center">
                                    {sidebarArray.map((item, index) => (
                                        <li key={index}>
                                            <a href={`#${item.link}`} className="flex py-2 text-base font-medium text-black lg:ml-6 lg:inline-flex">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                    <li>
                                        <div className="flex items-center gap-3 lg:ml-6">
                                            <a href={socialLinks.whatsapp.url} target="_blank" rel="noopener noreferrer" className="bg-green border border-green rounded-full inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white">
                                                Call on whatsapp
                                                <img src={whatsapp} alt="Whatsapp" className="ml-3 inline-block" />
                                            </a>
                                            <a href={socialLinks.facebook.url} target="_blank" rel="noopener noreferrer">
                                                <img src={facebook} alt="Facebook" className="inline-block" />
                                            </a>
                                            <a href={socialLinks.instagram.url} target="_blank" rel="noopener noreferrer">
                                                <img src={instagram} alt="Instagram" className="inline-block" />
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <button onClick={toggleSidebar} className="xl:hidden p-2 text-[#22221F] text-3xl">
                            {sidebarToggle ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
                        </button>
                    </div>
                </div>
            </header>
            <Sidebar
                sidebarArray={sidebarArray}
                sidebarToggle={sidebarToggle}
                handleNavLinkClick={handleNavLinkClick}
            />
        </>
    );
}
