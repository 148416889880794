import React from 'react';
import { Link } from 'react-router-dom';
import excellence from './assets/images/excellence-bg.png';
import player from './assets/images/player.png';

export default function Excellence() {
    return (
        <>
            <section className='py-8 h-[420px] lg:h-[750px] relative bg-no-repeat bg-cover flex items-center justify-center after:absolute after:bg-[#22221f80] after:top-0 after:bottom-0 after:h-full after:w-full' style={{ backgroundImage: `url(${excellence})` }}>
                <div className='container'>
                    <div className='text-center relative z-10'>
                        <h2 className='text-[#FFFBF0] text-3xl md:text-5xl font-regular font-heading max-w-[615px] m-auto'>Bringing excellence to
                            every training</h2>
                        <Link to=""
                            className="bg-[#C04E89] border border-[#C04E89] rounded-2xl inline-flex items-center justify-center py-3 px-7 text-center text-lg font-regular text-white mt-7">
                            <img src={player} alt="Whatsapp" className="mr-3 inline-block" />
                            Watch showreel
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}
