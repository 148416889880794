import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import banner from './assets/images/banner-bg.png';
import shape from './assets/images/shape.svg';
import socialLinks from './socialLinks.json';

export default function Banner() {

    const svgRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(svgRef.current,
            { y: 0 },
            { 
                y: 5, 
                duration: 2, // Adjusted duration for slower animation
                repeat: -1, 
                
                ease: "power1.out" // Added ease-out effect
            }
        );
    }, []);

    return (
        <>
            <section className='bg-floral py-5'>
                <div className='container'>
                    <div className='relative'>
                        <img src={banner} alt="Banner" className='w-full h-[624px] lg:h-auto object-cover rounded-2xl' />
                        <div className='absolute bottom-6 left-0 px-5 md:px-8 py-14'>
                            <span className='text-secondary text-base font-medium uppercase'>yoga with Mitushi Makwana</span>
                            <h1 className='text-6xl text-floral font-heading font-regular max-w-[550px] mt-5'>Online yoga classes for pregnancy </h1>
                            <p className='text-floral text-lg font-regular max-w-[550px] mt-5'>Personalized Yoga and Wellness Classes for Pregnant Women from Regenerative to Vinyasa: Tailored Practices for Every Stage</p>
                            <div className='flex items-center mt-7'>
                            <a href={socialLinks.whatsapp.url}
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="bg-secondary border border-secondary rounded-full inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white">
                                    Contact us
                                </a>
                                {/* <Link to=""
                                    className="inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white">
                                    Start now
                                </Link> */}
                            </div>
                        </div>
                        <div className='absolute -right-[3%] md:right-[7%] bottom-0'>
                            <div className='relative'>
                                <img src={shape} alt="Shape" />
                                <svg className='absolute top-6 left-0 right-0 m-auto' width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.33301 17.668V12.3346C1.33301 6.44357 6.10901 1.66797 12 1.66797C17.891 1.66797 22.667 6.44357 22.667 12.3346V17.668C22.667 23.559 17.891 28.3346 12 28.3346C6.10901 28.3346 1.33301 23.559 1.33301 17.668Z" stroke="#22221F" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                <svg ref={svgRef} className='absolute top-[31px] left-0 right-0 m-auto' width="2" height="11" viewBox="0 0 2 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1V10" stroke="#22221F" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
