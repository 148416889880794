import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Keyboard } from 'swiper/modules';
import socialLinks from './socialLinks.json';

import signal from './assets/images/signal.png';
import clock from './assets/images/clock.png';

// Import session data from JSON
import sessionData from './RecentClasses.json';

const whatsapp = '9727848918';

const createWhatsAppLink = (sessionTitle, sessionSuitableFor, sessionInclusion, sessionBenefits, additionalDetails) => {
    const boldTitle = `*${sessionTitle}*`;
    const message = `Hello, I am interested in the ${boldTitle} session. Suitable for: ${sessionSuitableFor}. Includes: ${sessionInclusion}. Benefits: ${sessionBenefits}.${additionalDetails ? ` Additional Details: ${additionalDetails}` : ''}`;
    return `https://wa.me/${whatsapp}?text=${encodeURIComponent(message)}`;
};

export default function SwiperSlider() {
    return (
        <div className="swiper-container custom-swipper-slider relative">
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                mousewheel={true}
                keyboard={true}
                modules={[Autoplay, Navigation, Keyboard]}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                }}
                className="mySwiper"
            >
                {sessionData.map((session, index) => (
                    <SwiperSlide key={index}>
                        <div className='relative'>
                            <img src={require(`${session.imgSrc}`)} alt={`slider${index + 1}`} className='w-full rounded-t-2xl' />
                        </div>
                        <div className='bg-white p-7 rounded-b-2xl min-h-[276px]'>
                            <h3 className='text-3xl font-heading font-bold text-tertiary'>{session.title}</h3>
                            <p className='text-lg font-regular text-tertiary mt-5 mb-2'>
                                <strong>Suitable for:</strong> {session.suitableFor}
                            </p>
                            <p className='text-lg font-regular text-tertiary mb-2'>
                                <strong>Inclusion:</strong> {session.inclusion}
                            </p>
                            <p className='text-lg font-regular text-tertiary mb-2'>
                                <strong>Benefits:</strong> {session.benefits}
                            </p>
                            {session.additionalDetails && (
                                <p className='text-lg font-regular text-tertiary mb-7'>
                                    <strong>Additional Details:</strong> {session.additionalDetails}
                                </p>
                            )}
                            <a 
                                href={createWhatsAppLink(session.title, session.suitableFor, session.inclusion, session.benefits, session.additionalDetails)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='text-secondary font-medium text-base'
                            >
                                Book Free Session
                            </a>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className='flex items-center'>
                <div className="swiper-button-prev swiper-custom-prev bg-[#22221F] rounded-full mr-4">
                    <i className="fas fa-arrow-left"></i>
                </div>
                <div className="swiper-button-next swiper-custom-next bg-[#22221F] rounded-full">
                    <i className="fas fa-arrow-right"></i>
                </div>
            </div>
        </div>
    );
}
