import React from 'react';
import SwiperSlider from './SwiperSlider';
export default function RecentClasses() {
    return (
        <>
            <section className='bg-primary py-20'>
                <div className='container'>
                    <div className='flex items-center justify-between'>
                        <h2 className='text-2xl md:text-5xl text-white font-regular font-heading'>My recent classes</h2>
                        {/* <Link to=""
                            className="border border-white rounded-full inline-flex items-center justify-center py-3 px-5 md:px-7 text-center text-base md:text-lg font-semibold text-white">
                            see all
                        </Link> */}
                    </div>
                    <div className='mt-8'>
                        <SwiperSlider />
                    </div>
                </div>
            </section>
        </>
    );
}
