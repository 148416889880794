import React, { useState, useEffect } from 'react';
import faqsData from './faqs.json'; // Import the JSON file

const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className='border border-[#F1DAE6] p-5 bg-[#FFF9FC] rounded-xl mt-4'>
        <div className='flex items-center justify-between gap-3' onClick={onClick}>
            <h3 className='text-[#22221F] text-lg font-medium flex-1'>{question}</h3>
           <i className={`fas ${isOpen ? 'fa-minus' : 'fa-plus'} text-[#C04E89] text-xl flex-none cursor-pointer`}></i>
        </div>
        {isOpen && <p className='text-[#22221F] font-regular text-base mt-2'>{answer}</p>}
    </div>
);

const FrequentlyQuestions = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [openFrequIndex, setOpenFrequIndex] = useState(null);

    useEffect(() => {
        // You can perform any setup or data fetching here if needed
    }, []);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const handleFrequClick = (index) => {
        setOpenFrequIndex(openFrequIndex === index ? null : index);
    };

    return (
        <section className='bg-[#FFF3F9] py-20'>
            <div className='container'>
                <h2 className='text-2xl md:text-5xl font-regular font-heading text-[#22221F] text-center'>Frequently Asked Questions</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 items-start md:gap-6 mt-8'>
                    <div>
                        {faqsData.faqs.map((faq, index) => (
                            <FAQItem
                                key={index}
                                question={faq.question}
                                answer={faq.answer}
                                isOpen={openIndex === index}
                                onClick={() => handleClick(index)}
                            />
                        ))}
                    </div>
                    <div>
                        {faqsData.frequently.map((frequently, index) => (
                            <FAQItem
                                key={index}
                                question={frequently.question}
                                answer={frequently.answer}
                                isOpen={openFrequIndex === index}
                                onClick={() => handleFrequClick(index)}
                            />
                        ))}
                    </div>
                </div>
                {/* <div className='mt-8 text-center'>
                    <button
                        className="bg-[#C04E89] border border-[#C04E89] rounded-2xl inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white">
                        View More
                    </button>
                </div> */}
            </div>
        </section>
    );
};

export default FrequentlyQuestions;
