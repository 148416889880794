import React from 'react';


const CompanyLogo = () => {

    const names = [
        'Pre conception',
        'Pregnancy Serenity',
        'Garbh sanskar',
        'Meditation batch',
        'Prenatal classes'
    ];

    // Create an array with 30 items by repeating the names
    const items = [];
    for (let i = 0; i < 30; i++) {
        items.push(names[i % names.length]);
    }

    return (

        <div className="client-logos bg-floral py-10 border-b border-[#562F53]">
            <div className="client-logo_items">
                {items.map((name, index) => (
                    <div key={index} className="text_item text-5xl font-heading">
                        <i class="fa-solid fa-circle text-xl mr-2 align-middle"></i>
                        {name}
                    </div>
                ))}
            </div>
            <div className="client-logo_items">
                {items.map((name, index) => (
                    <div key={index + items.length} className="text_item text-5xl font-heading">
                        <i class="fa-solid fa-circle text-xl mr-2 align-middle inline-block"></i>
                        {name}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default CompanyLogo;
