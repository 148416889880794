import React from 'react';
import service1 from './assets/images/service1.png';
import service2 from './assets/images/service2.png';
import service3 from './assets/images/service3.png';
import service4 from './assets/images/service4.png';
import service5 from './assets/images/service5.png';
import service6 from './assets/images/service6.png';

export default function CareService() {
    return (
        <>
            <section className='bg-[#FFF3F9] py-20'>
                <div className='container'>
                    <h2 className='text-3xl md:text-5xl font-regular font-heading text-[#22221F]'>Our Care & Services</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7'>
                        <div>
                            <img src={service1} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#C04E89]'>Pre conception</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                        <div>
                            <img src={service2} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#fa8ec6]'>IVF special batch</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                        <div>
                            <img src={service3} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#C04E89]'>Meditation batch</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                        <div>
                            <img src={service4} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#C04E89]'>Prenatal classes</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                        <div>
                            <img src={service5} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#C04E89]'>Garbh sanskar</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                        <div>
                            <img src={service6} alt="Pre Pregnancy" className='w-full rounded-xl' />
                            <div className='flex items-center justify-between group mt-3'>
                                <h3 className='text-3xl font-heading font-regular text-[#22221F] group-hover:text-[#C04E89]'>Postnatal Classes</h3>
                                <i className="fas fa-arrow-right text-[#22221F] group-hover:text-[#C04E89] cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-8 text-center'>
                        <button
                            className="bg-[#C04E89] border border-[#C04E89] rounded-2xl inline-flex items-center justify-center py-3 px-7 text-center text-lg font-semibold text-white">
                            View More
                        </button>
                    </div> */}
                </div>
            </section>
        </>
    );
}
