import './assets/css/App.css';
import Header from './Header';
import Banner from './Banner';
import PrePregnancy from './PrePregnancy';
import RecentClasses from './RecentClasses';
import Excellence from './Excellence';
import Institute from './Institute';
import CompanyLogo from './CompanyLogo';
import WorldWide from './WorldWide';
import CareService from './CareService';
import TestimonailSlider from './TestimonailSlider';
import FrequentlyQuestions from './FrequentlyQuestions';
import Footer from './Footer';
import TagManager from 'react-gtm-module';

const tagManagerArgs={
  gtmId: 'GTM-NG3LH4W3'
};

function App() {
  TagManager.initialize(tagManagerArgs);
  return (
    <>
      <Header />

      <section id="home">
        <Banner />
      </section>

      <section id="about">
        <PrePregnancy />
      </section>

      <section id="class">
        <RecentClasses />
      </section>

      <section id="excellence">
        <Excellence />
      </section>

      <section id="institute">
        <Institute />
      </section>

      <section id="worldwide">
        <WorldWide />
      </section>

      <section id="company-logo">
        <CompanyLogo />
      </section>

      <section id="care-service">
        <CareService />
      </section>

      <section id="testimonial" className='bg-floral py-20'>
        <div className='container'>
          <div className='text-center mb-8'>
            <h2 className='text-5xl font-heading font-regular'>Testimonials</h2>
          </div>
          <TestimonailSlider />
        </div>
      </section>

      <FrequentlyQuestions />

      <Footer />
    </>
  );
}

export default App;
