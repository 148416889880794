import React from 'react';
import prePregnancy from './assets/images/pre-pregnancy.png';
import bgLogo from './assets/images/bg-logo-opcity.png';

export default function PrePregnancy() {
    return (
        <>
            <section className='bg-floral py-20 relative'>
                <img src={bgLogo} alt="Bg Logo" className='absolute bottom-0 lg:-bottom-[247px] right-0' />
                <div className='container'>
                    <div className='text-center'>
                        <img src={prePregnancy} alt="Pre Pregnancy" className='inline-block' />
                        <h2 className='text-primary text-[25px] md:text-[48px] font-regular font-heading max-w-[1000px] m-auto pt-5 md:leading-[60px]'>Pregnancy Serenity Pre-Pregnancy, Pregnancy
                            and After Birth Classes</h2>
                        <h3 className='text-[#22221F] text-xl md:text-3xl font-regular font-heading max-w-[1271px] m-auto pt-5'>We are excited to announce that Pregnancy Serenity is comprehensive
                            program supporting every stage of your  motherhood journey: from pre-
                            conception planning through prenatal care and into postnatal as well. We empower
                            you with knowledge, guidance and community every stage of the way.</h3>
                    </div>
                </div>
            </section>
        </>
    );
}
