import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Navigation, Mousewheel, Keyboard } from 'swiper/modules';

import rating from './assets/images/rating.png';
import img from './assets/images/testimonail-img.png';


export default function TestimonailSlider() {
    return (
        <>
            <div className="swiper-container md:max-w-[80%] m-auto custom-testimonail-slider relative">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        freeMode={true}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Autoplay, Navigation, Mousewheel, Keyboard]}
                        className="mySwiper flex-1"
                    >

                        <SwiperSlide>
                            <div className='bg-[#608BB6] py-14 px-14 md:px-24 rounded-3xl text-center'>
                                <img src={img} alt="img" className='block m-auto' />
                                <img src={rating} alt="rating" className='block m-auto py-5' />
                                <h5 className='text-lg md:text-2xl font-regular font-heading text-white md:max-w-[662px] m-auto'>Mitushi maam is very well knowledgeable and kind. She gives out positive aura and her voice is very soothing during meditation. Very good classes covering meditation, mantra chanting and light exercises.</h5>
                                <p className='text-lg font-medium text-white pt-5'>Ishika Agrawal</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='bg-[#608BB6] py-14 px-14 md:px-24 rounded-3xl text-center'>
                                <img src={img} alt="img" className='block m-auto' />
                                <img src={rating} alt="rating" className='block m-auto py-5' />
                                <h5 className='text-lg md:text-2xl font-regular font-heading text-white md:max-w-[662px] m-auto'>Mitushi maam is very well knowledgeable and kind. She gives out positive aura and her voice is very soothing during meditation. Very good classes covering meditation, mantra chanting and light exercises.</h5>
                                <p className='text-lg font-medium text-white pt-5'>Ishika Agrawal</p>
                            </div>
                        </SwiperSlide>


                    </Swiper>
                    <div className="swiper-button-next bg-[#C04E89] rounded-full">
                            <i className="fas fa-arrow-right"></i>
                        </div>
                    <div className="swiper-button-prev bg-[#C04E89] rounded-full ">
                        <i className="fas fa-arrow-left"></i>
                    </div>
                    
               
            </div >
        </>
    );
}
